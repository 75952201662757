<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th style="width: 15%">
            Tên khách hàng <span style="color: #ff0000">*</span>
          </th>
          <th style="width: 15%">
            Số điện thoại <span style="color: #ff0000">*</span>
          </th>
          <th style="width: 10%">Nguồn</th>
          <th style="width: 10%">
            Năm sinh<span style="color: #ff0000">*</span>
          </th>
          <th style="width: 20%">
            Phân loại <span style="color: #ff0000">*</span>
          </th>
          <!-- <th>Khóa<span style="color: #ff0000">*</span></th> -->
          <th style="width: 20%">Coach phụ trách<span style="color: #ff0000">*</span></th>
          <th style="width: 20%">Gói dịch vụ<span style="color: #ff0000">*</span></th>
          
          <th></th>
        </tr>
      </thead>
      <tbody>
        <customer-row
          v-for="(customer, index) in listCustomer"
          :key="index"
          :customerData="customer"
          :customerIndex="index"
        />
      </tbody>
    </table>

    <div class="d-flex justify-content-end align-items-center mt-4">
      <button class="btn btn-secondary" @click="addCustomer">
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
        </span>
        Thêm một khách hàng khác
      </button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('customerReceives');
export default {
  name: 'FormCreate',
  components: {
    'customer-row': () => import('./CustomerReceiveRow.vue'),
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['listCustomer']),
  },
  methods: {
    ...mapActions(['addCustomer']),
  },
};
</script>
<style lang="scss" scoped>
.table {
   width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

// .table th, .table td {
 
//   text-align: left;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

.required {
  color: #ff0000;
}
</style>